export const colors = {
    white: '#ffffff',
    orange: '#F2994A',
    orange100: '#ED8F3B',
    blue: '#2962FF',
    grey100: '#F5F5F5',
    grey200: '#EEEEEE',
    grey300: '#E0E0E0',
    grey400: '#BDBDBD',
    grey600: '#757575',
    grey700: '#616161',
    grey900: '#212121',

    redA700: '#DD2C00',
    red100: '#FFCCBC',

    blueA700: '#2962FF',

    teal1: '#9BE9EE',
    teal4: '#0F565B'
}

export const availableColors = Object.keys(colors)